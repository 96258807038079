<template>
  <v-container fluid>
    <iframe :src="getters_single_staff.avatar_url" frameborder="0"></iframe>
  </v-container>
</template>

<script>
  import { defineComponent } from "vue";
  import { useGetters } from "vuex-composition-helpers";

  export default defineComponent({
    setup() {
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      return {
        getters_single_staff,
      };
    },
  });
</script>
